<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12">
                <h1 class="mt-4 fw-600 font-lg"><i class="fas fa-chart-pie mr-2 mb-2"></i>Charts Courses</h1>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <apexchart type="pie" :options="summary.options" :series="summary.series"></apexchart>
                                    </div>
                                </div>
                            </div> 
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <apexchart type="bar" :options="content.options" :series="content.series"></apexchart>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mb-5 pb-5">
            <div class="col-11 text-center py-3">
                <div class="my-3" v-show="loadCourse">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <img :src="'/images/empty.png'" v-show="courseNotExist" alt="" width="300">
                <h1 class="text-muted" v-show="courseNotExist">Data don't exist</h1>
            </div>
            <div class="col-12" v-if="courseExist">
                <h1 class="mt-4 fw-600 font-lg"><i class="fas fa-globe-asia mr-2 mb-2"></i>Published Courses</h1>
                <splide :options="slide">
                    <splide-slide v-for="(item, index) in courses" :key="index">
                        <div class="card w-100 shadow-xss border-0 rounded-lg overflow-hidden p-4 m-auto">
                            <div class="card-image w-100 mb-3 overflow-hidden rounded-lg">
                                <router-link :to="'/admin/courses/'+ item.slug +'/home'" class="position-relative d-block"><img :src="item.banner ? item.banner : 'https://via.placeholder.com/400x300.png'" alt="image" class="w-100"></router-link>
                            </div>
                            <div class="card-body">
                                <span class="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-current mr-1">{{item.category}} - {{item.level}}</span>
                                <span class="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right"><span class="font-xsssss">Rp</span> {{item.price | numFormat('0,0')}}</span>
                                <h4 class="fw-700 font-xss mt-3 lh-28 mt-0"><router-link :to="'/admin/courses/'+ item.slug +'/home'" class="text-dark text-grey-900">{{item.name}} </router-link></h4>
                                <h6 class="font-xssss text-grey-500 fw-600 ml-0 mt-2">{{item.name}}</h6>
                                <hr>
                                <div class="row d-flex justify-content-between">
                                    <router-link title="Announcements" :to="`/admin/courses/${item.slug}/announcements`" class="col"><i class="text-muted fas fa-bullhorn"></i></router-link>
                                    <router-link title="Assignment" :to="`/admin/courses/${item.slug}/assignments`" class="col"><i class="text-muted fas fa-clipboard-list"></i></router-link>
                                    <router-link title="Attendance" :to="`/admin/courses/${item.slug}/attendance`" class="col"><i class="text-muted fas fa-check"></i></router-link>
                                    <router-link title="File" :to="`/admin/courses/${item.slug}/files`" class="col"><i class="text-muted fas fa-folder"></i></router-link>
                                </div>
                            </div>
                        </div>
                    </splide-slide>
                </splide>
            </div>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "Dashboard",
    data(){
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            courses: [],
            loadCourse: true,
            courseExist: false,
            courseNotExist: false,
            summary : {
                options: {
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                },
                series: [],
            },
            content : {
                options: {
                    noData: {  
                        text: "Loading...",  
                        align: 'center',  
                        verticalAlign: 'middle',  
                        offsetX: 0,  
                        offsetY: 0,  
                        style: {  
                            color: "#000000",  
                            fontSize: '14px',  
                            fontFamily: "Helvetica"  
                        }  
                    },
                },
                series: [],
            },
            slide: {
                rewind : true,
                perPage : 3,
                gap : '1rem',
                autoplay : true,
                arrows : false,
                breakpoints: {
                    '800' : {
                        perPage: 1,
                        gap: '10rem'
                    },
                    '640' : {
                        perPage: 1,
                        gap: '4rem'
                    },
                    '480' : {
                        perPage: 1,
                        gap: '2rem'
                    },
                }
            },
        }
    },
    created() {
        this.getCourse()
        this.getSummaryMember()
        this.getSummaryContent()
    },
    methods: {
        async getSummaryMember() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/dashboard/get-course-member-chart`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.summary = res.data.data
                this.summary.options.legend = {
                    position: 'bottom',
                    formatter: function(value) {
                        var clamp = '...';
                        var node = document.createElement('div');
                        node.innerHTML = value;
                        var content = node.textContent;
                        return content.length > 10 ? content.slice(0, 10) + clamp : content;
                    },
                    
                }
            })
        },
        async getSummaryContent() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/dashboard/get-course-content-chart`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.content = res.data.data
                this.content.options.xaxis.labels = {
                    formatter: function(value) {
                        var clamp = '...';
                        var node = document.createElement('div');
                        node.innerHTML = value;
                        var content = node.textContent;
                        return content.length > 13 ? content.slice(0, 13) + clamp : content;
                    },
                    maxHeight: 100,
                    
                }
                this.content.options.chart.height = '100%'
            })
        },
        async getCourse() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/dashboard`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.loadCourse = false
                if (res.data.length == 0) {
                    this.courseNotExist = true
                } else {
                    this.courseExist = true
                    this.courses = res.data
                }
            }).catch((err) => console.error(err))
        }
    }
}
</script>
